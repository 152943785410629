<template>
   <div class="container-fluid">
       <div class="row">
           <div class="card">
               <div class="card-header d-flex justify-content-between">
                   <div class="header-title">
                       <h4 class="card-title">Editar cliente</h4>
                   </div>
               </div>
               <div class="card-body">
                   <div class="new-user-info">
                       <form @submit.prevent="onSubmit()">
                           <div class="row">
                              <div class="form-group col-md-6">
                                 <label for="name">Nome:</label>
                                 <input 
                                    type="text" 
                                    v-model="clientDetail.Nome" 
                                    class="form-control"
                                    :class="`form-control ${$v.clientDetail.Nome.$error ? 'is-invalid' : ''}`" 
                                    id="name" 
                                    placeholder="Nome"
                                    @input="$v.clientDetail.Nome.$touch()" 
                                    @blur="$v.clientDetail.Nome.$touch()"
                                 >
                              </div>
                              <div class="form-group col-md-6">
                                 <label for="vat">Contribuinte:</label>
                                 <input 
                                    type="text" 
                                    v-model="clientDetail.Contribuinte" 
                                    class="form-control"
                                    :class="`form-control ${$v.clientDetail.Contribuinte.$error ? 'is-invalid' : ''}`" 
                                    id="vat" 
                                    placeholder="Contribuinte"
                                    @input="$v.clientDetail.Contribuinte.$touch()" 
                                    @blur="$v.clientDetail.Contribuinte.$touch()"
                                 >
                              </div>
                              <div class="form-group col-md-6">
                                 <label for="add1">Morada:</label>
                                 <input 
                                    type="text" 
                                    v-model="clientDetail.Morada" 
                                    class="form-control"
                                    :class="`form-control ${$v.clientDetail.Morada.$error ? 'is-invalid' : ''}`" 
                                    id="address" 
                                    placeholder="Morada"
                                    @input="$v.clientDetail.Morada.$touch()" 
                                    @blur="$v.clientDetail.Morada.$touch()"
                                 >
                              </div>
                              <div class="form-group col-md-6">
                                 <label for="add2">Telemóvel:</label>
                                 <input 
                                    type="text" 
                                    v-model="clientDetail.Telemóvel" 
                                    class="form-control"
                                    :class="`form-control ${$v.clientDetail.Telemóvel.$error ? 'is-invalid' : ''}`" 
                                    id="phoneNumber" 
                                    placeholder="Telemóvel"
                                    @input="$v.clientDetail.Telemóvel.$touch()" 
                                    @blur="$v.clientDetail.Telemóvel.$touch()"
                                 >
                              </div>
                           </div>
                           <hr>
                           <button type="submit" class="btn btn-primary">Atualizar</button>
                       </form>
                   </div>
               </div>
           </div>
       </div>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, integer } from 'vuelidate/lib/validators'
export default {
   name:'ClientEdit',
   mixins: [validationMixin],
   validations: {
      clientDetail: {
         Nome: {required},
         Contribuinte: { required, integer },
         Morada:{ required },
         Telemóvel:{ required }
      }
   },
   data() {
      return {
         clientDetail: {},
         clients: JSON.parse(localStorage.getItem('clients'))
      }
   },
   mounted () {
      if(this.$route.params.client) {
         this.clientDetail = this.$route.params.client;
      }
      else{
         this.$router.push({name:'app.clients-list'});
      }
   },
   methods:{
      onSubmit()
      {
         let clients = JSON.parse(localStorage.getItem('clients'));
         this.$v.$touch()
         if (!this.$v.$invalid) {
            for(let index in clients) {
               if(clients[index].id === this.clientDetail.id) {
                  clients[index] = {Nome: this.clientDetail.Nome, Contribuinte: this.clientDetail.Contribuinte, Morada: this.clientDetail.Morada, Telemóvel: this.clientDetail.Telemóvel}
                  localStorage.setItem('clients', JSON.stringify(clients));
                  break;
               }
            }
            this.$router.push({name:'app.clients-list'})
         }
      }
   }
}
</script>